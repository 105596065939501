.oo-sticky-basket {
  position: sticky;
  bottom: 0;
  padding: 15px;
  background: black;
  color: white;
  font-size: 0.9rem;

  display: flex;

  &:hover {
    cursor: pointer;
  }
}
