@import 'bootstrap';
.oo-order {
  position: relative;
}

@include media-breakpoint-down(sm) {
  .oo-order-content {
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
  }
}

.oo-order-content div {
  position: relative;
  z-index: 100;
}
.oo-order-map-bg {
  z-index: 50;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
