@import '../custom.scss';
.oo-shopinfo {
  position: relative;
  text-align: left;
  border-left: none;
  border-right: none;
  .oo-shop-name {
    font-weight: bold;
  }
}

.oo-open-info {
  color: $third;
  font-size: $small-font-size;
  margin-bottom: 0;
  &:hover {
    cursor: pointer;
  }
}

.oo-shop-banner {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 200px;
}

.oo-open-info-details {
  padding: 0.5rem 0;
  font-size: $small-font-size;
  p {
    margin: 0;
  }
}

.oo-open-info-toggle {
}

.oo-shop-slogan {
  margin: 0 0 5px 0;
  color: $secondary;
}

.oo-shop-contact-info {
  font-size: $small-font-size;
  color: $primary;
  margin: 0;
  padding: 0;
  li {
    a {
      text-decoration: none;
    }
    list-style: none;
    margin: 0;
    padding: 2px 0;
    svg {
      margin-right: 3px;
    }
  }
}

#change-location-dropdown {
  text-decoration: none;
  font-size: 0.875rem;
  background-color: white;
  color: black;
  border: 1.5px solid black;
  margin-top: 10px;
  padding: 4px 8px;
  border-radius: 0;
}
