@import '../custom.scss';
.oo-menu-by-category {
  overflow-x: hidden;
  background-color: rgb(248, 248, 248);
  text-align: left;
  .oo-category-name {
    padding: 1.5rem 1rem;
    margin: 0;
    font-weight: bolder;
    font-size: 1.4rem;
  }
  .oo-menu-item-card {
    border-radius: 0;
    border-top: none;
    border-radius: 0;
    &:hover {
      cursor: pointer;
    }
    &.not-avail {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.6);
        left: 0;
        top: 0;
      }
    }
  }
  .oo-menu-item {
    display: flex;
    justify-content: space-between;
  }
  .oo-menu-item-left-content {
    padding-right: 15px;
  }
  .oo-menu-item-right-content {
    img {
      width: 120px;
      position: relative;
      right: -15px;
    }
  }
  .oo-menu-item-title {
    font-weight: 600;
    font-size: 0.9rem;
    margin: 0 0 0.2rem 0;
  }
  .oo-menu-item-desc {
    color: $secondary;
    font-size: 0.8rem;
    margin: 0 0 0.5rem 0;
  }
  .oo-menu-item-pricing {
    color: $primary;
    font-size: 0.9rem;
    margin: 0;
  }
  .oo-menu-item-avail {
    color: $danger-red;
    font-size: 0.9rem;
    margin: 0;
  }
}

.oo-menu-item-popular {
  margin-left: 10px;
  color: $third;
}
