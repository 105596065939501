@import 'bootstrap';
.oo-topnav {
  font-size: 0.9rem;
  color: white;
  display: flex;
  justify-content: flex-end;

  .oo-topnav-item {
    margin: 0 10px;
  }

  .oo-cart-container a {
    text-decoration: none;
    color: white;
  }
}
