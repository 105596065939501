@import 'bootstrap';
.modal-dialog {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.modal-content {
  height: auto;
  min-height: 100%;
  border-radius: 0;
}

.oo-user-nav-container {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
