@import '../../custom.scss';
@import 'bootstrap';
.oo-modal-item-cover {
  position: relative;
  height: 200px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.oo-close-icon-wrapper {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 10px;
  display: inline-block;
  color: white;
  &:hover {
    cursor: pointer;
    color: $secondary;
  }
}

.action-btn-container {
  display: block;
}

.oo-modal-footer {
  justify-content: center;
}

// Basket modal
.basket-item-desc {
  padding-left: 10px;
  .item-breakdown {
    font-size: 0.8rem;
    color: $secondary;
  }
}

@include media-breakpoint-up(sm) {
  .modal-content {
    min-height: 90%;
    height: 90%;
  }
}

// Single item model
.oo-single-item-modal {
  .modal-body,
  .modal-footer {
    background-color: white;
  }

  .oo-price-breakdown {
    width: 100%;
  }
}

apple-pay-button {
  --apple-pay-button-width: 140px;
  --apple-pay-button-height: 30px;
  --apple-pay-button-border-radius: 5px;
  --apple-pay-button-padding: 5px 0px;
}

// Payment modal
.oo-payment-modal {
  height: auto !important;
  .card-payment-btn {
    &:hover {
      background: none;
    }
  }
}
